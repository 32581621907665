import React, { FC, useEffect, useState } from "react";
import { GetDevicesFilter } from "../../../../services/SmartHomeDevices/types";
import MacAddressInput from "./components/MacAddressInput";
import DeviceTypeSelect from "./components/DeviceTypeSelect";

import "./style.scss";
import TextInput from "../../../../components/TextInput";

interface DevicesFilterMenuProps {
  onChange: (filter: GetDevicesFilter) => void;
}

const DevicesFilterMenu: FC<DevicesFilterMenuProps> = ({ onChange }) => {
  const [currentFilter, setCurrentFilter] = useState<GetDevicesFilter>({
    unitId: "",
    macAddress: "",
    deviceType: "",
  });
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const [unitId, setUnitId] = useState(currentFilter.unitId);
  const [macAddress, setMacAddress] = useState(currentFilter.macAddress);
  const [isMacValid, setIsMacValid] = useState(true);
  const [deviceType, setDeviceType] = useState(currentFilter.deviceType);

  const handleUnitId = (id: string) => {
    setUnitId(id);
  };
  const handleMacAddress = (mac: string) => {
    if (!mac) setIsMacValid(true);
    setMacAddress(mac);
  };
  const handleDeviceType = (types: string) => {
    setDeviceType(types);
  };

  useEffect(() => {
    const isChanged =
      currentFilter.unitId !== unitId ||
      currentFilter.macAddress !== macAddress ||
      currentFilter.deviceType !== deviceType;
    setIsFilterChanged(isChanged);
  }, [currentFilter, unitId, macAddress, deviceType]);

  const handleSubmit = () => {
    if (macAddress) {
      const isValid = macAddress.length === 17;
      setIsMacValid(isValid);
      if (!isValid) return;
    }

    const filter: GetDevicesFilter = {
      unitId: unitId?.trim().toUpperCase(),
      macAddress: macAddress,
      deviceType: deviceType,
    };
    setCurrentFilter(filter);
    onChange(filter);
  };

  return (
    <div className="devices-filter-menu-component">
      <div className="filter-form">
        <div className="unit-input">
          <TextInput
            label="Unit ID"
            onChange={handleUnitId}
            disabled={!!macAddress}
          />
        </div>
        <MacAddressInput
          onChange={handleMacAddress}
          invalid={!isMacValid}
          disabled={!!unitId}
        />
        <DeviceTypeSelect onChange={handleDeviceType} />
        <div className="submit">
          <button onClick={handleSubmit} disabled={!isFilterChanged}>
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};
export default DevicesFilterMenu;
