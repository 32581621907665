import {
  MonitoringDeviceReading,
  MonitoringDeviceType,
  RouterReading,
  SensorReading,
} from '../services/SmartHomeDevices/types';

export const formatReading = (
  DeviceType: MonitoringDeviceType,
  LatestReading?: MonitoringDeviceReading,
): string => {
  if (!LatestReading) return 'N/A';

  if (DeviceType === MonitoringDeviceType.SENSOR) {
    const sensor = LatestReading as SensorReading;
    return `${sensor.Decibels} dB`;
  } else {
    const router = LatestReading as RouterReading;
    return router.Connected.toString() ?? '0';
  }
};
