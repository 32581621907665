export class CSV {
  static stringify(content: Record<string, string | boolean | number>[]): string {
    let keys: Set<string> = new Set<string>();
    for (let i = 0; i < content.length; i++) {
      const objectKeys = Object.keys(content[i]);
      for (let j = 0; j < objectKeys.length; j++) {
        keys.add(objectKeys[j]);
      }
    }

    const keyNames = Array.from(keys);
    const rows: string[] = [];
    for (let i = 0; i < content.length; i++) {
      const row: string[] = [];
      for (let j = 0; j < keyNames.length; j++) {
        const keyName = keyNames[j];
        const value = content[i][keyName];
        if (typeof value === `object`) throw new Error("stringifyCsv cannot parse an object");
        row.push(CSV.escapeCsvValue(value));
      }
      rows.push(row.join(","));
    }

    const headerRow = [];
    for (let i = 0; i < keyNames.length; i++) {
      const headerVal = CSV.escapeCsvValue(keyNames[i]);
      headerRow.push(headerVal);
    }
    rows.unshift(headerRow.join(","));

    return rows.join("\n");
  }

  private static escapeCsvValue(value: string | boolean | number): string {
    if (typeof value !== "string") return value.toString();

    const escapeWords = ["\n", "\r", ",", '"'];
    let i = 0;
    for (i = 0; i < escapeWords.length; i++) {
      if (value.includes(escapeWords[i])) break;
    }
    if (i === escapeWords.length) return value;

    const escaped = value.replace(new RegExp('"', "g"), '""');
    return `"${escaped}"`;
  }
}
