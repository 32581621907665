export const formatDate = (date: string | Date, timeZone?: string): string => {
  const d = new Date(date);
  return d.toLocaleDateString("en-us", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    timeZone,
  });
};

export const formatTime = (date: string | Date, timeZone?: string): string => {
  const d = new Date(date);
  return d
    .toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone,
    })
    .toLowerCase();
};

export const formatDateTime = (
  date: string | Date | undefined | null,
  timeZone?: string
): string => {
  if (!date) return "N/A";
  return `${formatDate(date, timeZone)} · ${formatTime(date, timeZone)}`;
};
