import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
  isAxiosError,
} from "axios";
import {
  DEFAULT_TIMEOUT,
  HttpResponseError,
  HttpTimeoutError,
} from "./http.error";

export class HttpSender {
  private axiosInstance: AxiosInstance;
  constructor(baseURL: string) {
    this.axiosInstance = axios.create({
      baseURL,
      timeout: DEFAULT_TIMEOUT,
    });
  }

  public async get<T>(
    url: string,
    config: AxiosRequestConfig = {}
  ): Promise<T | null> {
    const res = await this.send<T>("get", url, undefined, config);
    if (this.is200(res)) return res.data;
    else if (res.status === 404) return null;
    throw new HttpResponseError(res);
  }

  public async post<T>(
    url: string,
    body: any,
    config: AxiosRequestConfig = {}
  ): Promise<T | null> {
    const res = await this.send<T>("post", url, body, config);
    if (this.is200(res)) return res.data;
    throw new HttpResponseError(res);
  }

  public async put<T>(
    url: string,
    body: any,
    config: AxiosRequestConfig = {}
  ): Promise<T | null> {
    const res = await this.send<T>("put", url, body, config);
    if (this.is200(res)) return res.data;
    throw new HttpResponseError(res);
  }

  public async patch<T>(
    url: string,
    body: any,
    config: AxiosRequestConfig = {}
  ): Promise<T | null> {
    const res = await this.send<T>("patch", url, body, config);
    if (this.is200(res)) return res.data;
    throw new HttpResponseError(res);
  }

  public async delete<T>(
    url: string,
    config: AxiosRequestConfig = {}
  ): Promise<T | null> {
    const res = await this.send<T>("delete", url, undefined, config);
    if (this.is200(res)) return res.data;
    throw new HttpResponseError(res);
  }

  private async send<T>(
    method: Method,
    url: string,
    data: any,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<T, any>> {
    try {
      const req: AxiosRequestConfig = {
        method,
        url,
        data,
        ...config,
      };
      this.setAuth(req);
      return this.axiosInstance.request(req);
    } catch (err) {
      if (isAxiosError(err) && err.response) return err.response;

      let error = err as any;
      if (error.code === "ECONNABORTED" && error.message.includes("timeout")) {
        throw new HttpTimeoutError(error);
      }
      console.error(err);
      throw err;
    }
  }

  private setAuth(config: AxiosRequestConfig): void {
    const token = localStorage.getItem("accessToken");
    if (token) {
      if (!config.headers) config.headers = {};
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  private is200(res: AxiosResponse): boolean {
    return 200 <= res.status && res.status < 300;
  }
}
