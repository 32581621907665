const fromArray = (names: (string | undefined)[]): string => {
  return names.filter((n) => !!n).join(" ");
};

export const classnames = (
  names?: (string | undefined)[] | Record<string, boolean>
): string => {
  if (!names) return "";
  else if (Array.isArray(names)) return fromArray(names);
  else {
    const enabledNames: string[] = [];
    Object.entries(names).forEach(([className, enabled]) => {
      if (enabled) enabledNames.push(className);
    });
    return fromArray(enabledNames);
  }
};
