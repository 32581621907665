import React from "react";
import { ImplicitFlow } from "@vacasa/react-components-lib";

import "./App.scss";
import {
  ENV_NAME,
  IDP_AUDIENCE,
  IDP_CLIENT_ID,
  IDP_REDIRECT_URI,
  IDP_SCOPES,
} from "./config";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import PageNotFound from "./pages/PageNotFound";
import PreAuthorization from "./components/PreAuthorization";
import PostAuthorization from "./components/PostAuthorization";

function App() {
  return (
    <div className="App">
      <PreAuthorization>
        <ImplicitFlow
          directoryHint="onelogin"
          clientId={IDP_CLIENT_ID}
          redirectUrl={IDP_REDIRECT_URI}
          scopes={IDP_SCOPES}
          audience={IDP_AUDIENCE}
          tokenRefresh={true}
          // on local authenticate on stage
          env={ENV_NAME === "local" ? "stage" : (ENV_NAME as any)}
          followRedirect={false}
        >
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<PostAuthorization />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        </ImplicitFlow>
      </PreAuthorization>
    </div>
  );
}

export default App;
