import React, { FC, useState } from "react";
import { formatMacAddressForDisplay } from "../../../../../../lib/format.macaddress";
import TextInput from "../../../../../../components/TextInput";

interface MacAddressInputProps {
  onChange: (mac: string) => void;
  invalid?: boolean;
  disabled?: boolean;
}

const MacAddressInput: FC<MacAddressInputProps> = ({
  onChange,
  invalid = false,
  disabled = false,
}) => {
  const [macAddress, setMacAddress] = useState("");

  const handleChange = (mac: string) => {
    if (macAddress !== mac) {
      setMacAddress(mac);
      onChange(mac);
    }
  };

  return (
    <div className="mac-address-input-component">
      <TextInput
        label="Mac Address"
        onChange={handleChange}
        format={formatMacAddressForDisplay}
        validationError={invalid ? "Invalid Mac Address" : undefined}
        disabled={disabled}
      />
    </div>
  );
};

export default MacAddressInput;
