import React, { FC } from "react";

import "./style.scss";

const PageNotFound: FC = () => {
  return (
    <div className="page-not-found-component">
      <h2>404 Page Not Found</h2>
    </div>
  );
};

export default PageNotFound;
