import { SMART_HOME_DEVICES_URL } from "../../config";
import { formatMacAddressForBackend } from "../../lib/format.macaddress";
import { HttpSender } from "../lib/http.sender";
import { GetDevicesFilter, IPaginated, MonitoringDevice } from "./types";

export class SmartHomeDevices {
  private http: HttpSender;
  constructor() {
    this.http = new HttpSender(SMART_HOME_DEVICES_URL);
  }

  public async getDevices(filter: GetDevicesFilter | null, page?: string) {
    const params = new URLSearchParams({
      pageSize: "250",
      includeReading: "true",
    });
    if (page) params.set("page", page);

    if (filter?.unitId) params.set("filterBy", `DeviceGroup=${filter.unitId}`);
    else if (filter?.macAddress)
      params.set("filterBy", `MacAddress=${formatMacAddressForBackend(filter.macAddress)}`);
    if (filter?.deviceType) params.set("deviceTypes", filter.deviceType);

    return this.http.get<IPaginated<MonitoringDevice>>(`/all-devices?${params.toString()}`);
  }
}
