import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import "./style.scss";
import { GetDevicesFilter, MonitoringDevice } from "../../services/SmartHomeDevices/types";
import { SmartHomeDevices } from "../../services/SmartHomeDevices";
import DevicesDataTable from "./components/DevicesDataTable";
import DevicesFilterMenu from "./components/DevicesFilterMenu";
import ExportDialog from "./components/ExportDialog";

const Dashboard: FC = () => {
  const [filter, setFilter] = useState<GetDevicesFilter>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [isExporting, setIsExporting] = useState(false);

  const data = useRef<MonitoringDevice[]>();
  const nextPage = useRef<string>();

  useEffect(() => {
    // Reset when filters are updated
    data.current = undefined;
    nextPage.current = undefined;
  }, [filter]);

  // Called when getting data after initial api call
  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(undefined);
      const service = new SmartHomeDevices();
      const res = await service.getDevices(filter, nextPage.current);
      if (res) {
        if (data.current) data.current = data.current.concat(res.data);
        else data.current = res.data;
        nextPage.current = res.nextPage;
      }
    } catch (err) {
      if (err instanceof Error) setError(err.message);
      else setError("Unable to load devices");
    } finally {
      setIsLoading(false);
    }
  }, [filter]);

  // Loads data on init & whenever the filter is updated
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Updates filters
  const handleFilter = (newFilter: GetDevicesFilter) => {
    setFilter(newFilter);
  };

  const showExport = () => {
    setIsExporting(true);
  };
  const hideExport = () => {
    setIsExporting(false);
  };

  return (
    <div className="dashboard-component">
      <DevicesFilterMenu onChange={handleFilter} />
      <DevicesDataTable
        fetchData={fetchData}
        devices={data.current}
        page={nextPage.current}
        isLoading={isLoading}
        error={error}
        onExport={showExport}
      />
      {isExporting && (
        <ExportDialog
          fetchData={fetchData}
          devices={data.current}
          isLoading={isLoading}
          error={error}
          nextPage={nextPage.current}
          onClose={hideExport}
        />
      )}
    </div>
  );
};

export default Dashboard;
