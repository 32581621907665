import React, { FC } from "react";

interface PreAuthorizationProps {
  children: React.ReactElement;
}

const PreAuthorization: FC<PreAuthorizationProps> = (props) => {
  const { pathname, search } = window.location;

  if (pathname && pathname !== "/") {
    localStorage.setItem("auth-redirect", JSON.stringify({ pathname, search }));
  }

  return props.children;
};

export default PreAuthorization;
