import React, { FC, useState } from "react";
import "./style.scss";
import { classnames } from "../../lib/classnames";

interface TextInputProps {
  label?: string;
  format?: (value: string) => string;
  onChange: (value: string) => void;
  disabled?: boolean;
  validationError?: string;
}

const TextInput: FC<TextInputProps> = ({
  label,
  onChange,
  format,
  disabled,
  validationError,
}) => {
  const [value, setValue] = useState("");

  const handleChange = (e: any) => {
    let raw = e.target.value;
    if (format) raw = format(raw);
    setValue(raw);
    onChange(raw);
  };

  return (
    <div className="text-input-component">
      <div
        className={classnames({
          label: true,
          disabled: disabled === true,
        })}
      >
        {label}
      </div>
      <input
        type="text"
        onChange={handleChange}
        value={value}
        disabled={disabled}
        className={classnames({
          invalid: !!validationError,
        })}
      />
      <div className="validation-error">{validationError}</div>
    </div>
  );
};

export default TextInput;
