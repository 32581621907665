import React, { FC, useEffect, useState } from 'react';
import { MonitoringDevice } from '../../../../services/SmartHomeDevices/types';

import './style.scss';
import { Icon, Image } from '@vacasa/react-components-lib';
import { CSV } from '../../../../lib/csv';
import { formatMacAddressForDisplay } from '../../../../lib/format.macaddress';
import {  formatTemp } from '../../../../lib/format.temp';
import { formatReading } from '../../../../lib/format.reading';
import { formatThreshold } from '../../../../lib/format.threshold';

interface ExportDialogProps {
  fetchData: () => Promise<void>;
  devices?: MonitoringDevice[];
  isLoading: boolean;
  nextPage?: string;
  error?: string;
  onClose?: () => void;
}

const ExportDialog: FC<ExportDialogProps> = ({
  fetchData,
  devices,
  isLoading,
  nextPage,
  error,
  onClose,
}) => {
  const date = new Date().toISOString().split('T')[0];
  const [fileName, setFileName] = useState<string>(`monitoring-devices-${date}`);
  const [fileContent, setFileContent] = useState<string>();
  const [isEditing, setIsEditing] = useState<boolean>();
  const [contentUrl, setContentUrl] = useState<string>();

  /**
   * Load all data!
   *
   * fetchData() will update error OR nextPage, causing loop that will
   * continuously fetch more data until there are no more pages left.
   *
   * Once data is loaded it will parse it into a csv string.
   */
  useEffect(() => {
    if (!error && nextPage) fetchData();
    else if (devices) {
      const csv = CSV.stringify(
        devices.map((d) => ({
          'Finance Code': d.FinanceCode ?? '',
          'Unit Id': d.DeviceGroup,
          'Unit Code': d.UnitCode ?? '',
          'Device Type': d.DeviceType,
          'MAC Address': formatMacAddressForDisplay(d.MacAddress),
          'Registration Date': new Date(d.RegistrationDate).toISOString(),
          'Latest Reading Date': d.LatestReading?.ReadingDate
            ? new Date(d.LatestReading.ReadingDate).toISOString()
            : '',
          'Latest Reading': formatReading(d.DeviceType, d.LatestReading),
          'Temp': formatTemp(d.DeviceType, d.LatestReading),
          'Reading Threshold': formatThreshold(d),
          'Registered By': d.RegisteredByEmailAddress ?? d.UpdatedByEmailAddress,
          'Last Changed By': d.UpdatedByEmailAddress,
        })),
      );

      setFileContent(csv);
    }
  }, [fetchData, error, nextPage, devices]);

  const handleClose = () => {
    onClose?.();
  };

  const handleDownload = () => {
    if (contentUrl) {
      window.URL.revokeObjectURL(contentUrl);
      setContentUrl(undefined);
      setFileContent(undefined);
    }
    handleClose();
  };

  const showEdit = () => {
    setIsEditing(true);
  };
  const hideEdit = () => {
    setIsEditing(false);
  };

  const handleFileNameChange = (e: any) => {
    const value = e.target.value;
    setFileName(value);
  };

  /**
   * Creates and clicks a link to save the CSV client side
   */
  const renderSaveCsv = () => {
    if (!fileContent) return;

    const content = new Blob([fileContent], { type: 'text/plain' });
    const url = URL.createObjectURL(content);
    setTimeout(() => {
      // don't trigger a page render inside of a render cycle
      setContentUrl(url);
    }, 0);

    return (
      <div>
        <div className="title">Your document is ready!</div>
        <div>
          {isEditing ? (
            <div className="edit">
              <div className="edit-input">
                <input value={fileName} onChange={handleFileNameChange} />
                .csv
              </div>
              <button className="transparent icon" onClick={hideEdit}>
                &#10005;
              </button>
            </div>
          ) : (
            <div>
              <div className="message">{fileName}.csv</div>
              <button className="transparent icon" onClick={showEdit}>
                <Icon.Edit />
              </button>
            </div>
          )}
        </div>

        <a className="btn green" href={url} download={`${fileName}.csv`} onClick={handleDownload}>
          Download
        </a>
      </div>
    );
  };

  return (
    <div className="export-dialog-component">
      <div className="dialog-header">
        <div className="btn-wrapper">
          <button className="white btn-close-dialog" onClick={handleClose}>
            &#10005;
          </button>
        </div>
      </div>
      <div className="dialog-content">
        {error && (
          <div className="error">
            <div className="title">Unable to generate document</div>
            <div className="message">{error}</div>
            <div className="help-text">Please try agian in a few minutes</div>
          </div>
        )}
        {(isLoading || !fileContent) && (
          <div className="loading">
            <Image.Spinner width={94} height={94} />
            <div>
              <div className="title">Loading data</div>
              <div className="message">This might take a few minutes...</div>
            </div>
          </div>
        )}
        {fileContent && renderSaveCsv()}
      </div>
    </div>
  );
};

export default ExportDialog;
