import { Environment } from "./types";

function getEnvVar(varName: any): string {
  const value = process.env[varName];
  if (value) return value;

  const message = `Variable ${varName} is undefined or empty`;

  // Do not throw an error when unit testing
  if (process.env.NODE_ENV !== "test") throw new Error(message);
  else return varName;
}

export const ENV_NAME = getEnvVar(
  "REACT_APP_CUSTOM_NODE_ENV_SHORT"
) as Environment;

export const IDP_SCOPES =
  "device-data:read device-data:write users:read units:read";

export const IDP_GATEWAY = process.env["REACT_APP_IDP_GATEWAY"]; // empty on stage/prod
export const IDP_AUDIENCE = getEnvVar("REACT_APP_IDP_AUDIENCE");
export const IDP_REDIRECT_URI = getEnvVar("REACT_APP_IDP_REDIRECT_URI");
export const IDP_CLIENT_ID = getEnvVar("REACT_APP_IDP_CLIENT_ID");
export const SMART_HOME_DEVICES_URL = getEnvVar(
  "REACT_APP_SMART_HOME_DEVICES_URL"
);
