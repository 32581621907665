import {
  MonitoringDevice,
  MonitoringDeviceType,
  Router,
  Sensor,
} from '../services/SmartHomeDevices/types';

export const formatThreshold = (device: MonitoringDevice): string => {
  if (device.DeviceType === MonitoringDeviceType.SENSOR) {
    const sensor = device as Sensor;
    return `${sensor.NoiseAlertDecibelThreshold} dB`;
  } else {
    const router = device as Router;
    return router.ConnectedAlertThreshold.toString();
  }
};
