import React, { FC, useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../hooks/window.dimensions";
import { classnames } from "../../lib/classnames";

interface VerticalFillProps {
  children?: React.ReactNode;
  marginBottom?: number;
  className?: string;
}

const VerticalFill: FC<VerticalFillProps> = ({
  children,
  marginBottom,
  className,
}) => {
  const { height } = useWindowDimensions();
  const element = useRef<HTMLDivElement>(null);
  const [vertical, setVertical] = useState<number>();

  useEffect(() => {
    if (!element.current) return;

    const effectiveMargin = marginBottom ?? 0;
    const elementHeight = height - element.current.offsetTop - effectiveMargin;

    setVertical(elementHeight);
  }, [height, element, marginBottom]);

  return (
    <div
      className={classnames(["vertical-fill-component", className])}
      ref={element}
      style={{ height: vertical }}
    >
      {children}
    </div>
  );
};

export default VerticalFill;
