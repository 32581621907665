import React, { FC } from "react";
import { MonitoringDeviceType } from "../../../../../../services/SmartHomeDevices/types";
import SingleSelectInput from "../../../../../../components/SingleSelectInput";

interface DeviceTypeSelectProps {
  onChange: (type: string) => void;
}

const options = Object.values(MonitoringDeviceType).map((x) => ({
  value: x,
  display: x,
}));

const DeviceTypeSelect: FC<DeviceTypeSelectProps> = ({ onChange }) => {
  const handleChange = (value: string) => {
    onChange(value);
  };

  return (
    <div className="device-type-select-component">
      <SingleSelectInput
        label="Device Type"
        options={options}
        nullText="All"
        onChange={handleChange}
      />
    </div>
  );
};

export default DeviceTypeSelect;
