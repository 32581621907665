export function formatMacAddressForBackend(
  macAddress?: string
): string | undefined {
  const rawValue = macAddress?.trim().replace(/[^a-fA-F0-9]/g, "");
  return rawValue;
}

export function formatMacAddressForDisplay(macAddress?: string): string {
  const rawValue = formatMacAddressForBackend(macAddress);
  if (!rawValue) return "";

  let formattedValue = Array.from(rawValue);
  for (let i = 2; i < formattedValue.length; i += 3) {
    formattedValue.splice(i, 0, ":");
  }

  return formattedValue.join("").substring(0, 17).toUpperCase();
}
