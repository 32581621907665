import {
  MonitoringDeviceReading,
  MonitoringDeviceType,
  MonitoringDevice,
  Sensor,
  SensorReading,
} from '../services/SmartHomeDevices/types';

export const formatTemp = (
  DeviceType: MonitoringDeviceType,
  LatestReading?: MonitoringDeviceReading,
): string => {
  if (!LatestReading) return 'N/A';

  if (DeviceType === MonitoringDeviceType.SENSOR) {
    const sensor = LatestReading as SensorReading;
    if(sensor.Temperature)
    {
      return formatTempToString(sensor.Temperature);
    }
  }
  return '';
};
export const formatTempHigh = (device: MonitoringDevice): string => {
  if (!device) return 'N/A';

  if (device.DeviceType === MonitoringDeviceType.SENSOR) {
    const sensor = device as Sensor;
    if(sensor.TemperatureHighThreshold)
    {
      return formatTempToString(sensor.TemperatureHighThreshold);
    }
  }
  return 'N/A';
};

export const formatTempLow = (device: MonitoringDevice): string => {
  if (!device) return 'N/A';

  if (device.DeviceType === MonitoringDeviceType.SENSOR) {
    const sensor = device as Sensor;
    if(sensor.TemperatureLowThreshold)
    {
      return formatTempToString(sensor.TemperatureLowThreshold);
    }
  }
  return 'N/A';
};

  export const formatTempToString = (temp:number):string => {
    return `${temp} F/\n${fahrenheitToCelsius(temp)} C`
  }
  export const fahrenheitToCelsius = (fahrenheit: number) => {
    const c = (fahrenheit - 32) * (5 / 9);
    return Math.round(c);
  };

