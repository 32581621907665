import React, { FC, useState } from "react";
import "./style.scss";
import { classnames } from "../../lib/classnames";

interface SingleSelectProps {
  label?: string;
  options?: { value: string; display: string }[];
  initialValue?: string;
  nullText?: string;
  notNull?: boolean;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const SingleSelectInput: FC<SingleSelectProps> = ({
  label,
  options = [],
  initialValue = "",
  nullText = "--",
  notNull = false,
  onChange,
  disabled,
}) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: any) => {
    const raw = e.target.value;
    setValue(raw);
    onChange(raw);
  };

  return (
    <div className="single-select-input-component">
      <div
        className={classnames({
          label: true,
          disabled: disabled === true,
        })}
      >
        {label}
      </div>
      <select value={value} onChange={handleChange}>
        {!notNull && <option value="">{nullText}</option>}
        {options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.display}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SingleSelectInput;
