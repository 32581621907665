export enum MonitoringDeviceType {
  SENSOR = 'Sensor',
  ROUTER = 'Router',
}

export interface MonitoringDeviceReading {
  ReadingDate: string;
}

export interface SensorReading extends MonitoringDeviceReading {
  Decibels: number;
  Temperature: number;
  IsFahrenheit: boolean;
}

export interface RouterReading extends MonitoringDeviceReading {
  Connected: number;
  Visible: number;
}

export interface MonitoringDevice {
  DeviceType: MonitoringDeviceType;
  MacAddress: string;
  DeviceGroup: string;
  RegistrationDate: string;
  RegisteredByEmailAddress?: string;
  UpdatedByEmailAddress: string;
  LatestReading?: MonitoringDeviceReading;
  UnitCode?: string;
  FinanceCode?: string;
}

export interface Sensor extends MonitoringDevice {
  NoiseAlertDecibelThreshold: number;
  TemperatureLowThreshold: number;
  TemperatureHighThreshold: number;
}

export interface Router extends MonitoringDevice {
  ConnectedAlertThreshold: number;
}

export interface IPaginated<T> {
  data: T[];
  nextPage?: string;
}

export interface GetDevicesFilter {
  unitId?: string;
  macAddress?: string;
  deviceType?: string;
}
